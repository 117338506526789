.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.inputBoxes {
  min-height: 5vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  float: left;
  margin-bottom: 10px;
}

.statusIndicator {
    min-height: 5vh;
    min-width: 30vh;
    font-size: calc(5px + 1vmin);
    float: right;
    color: black;
    margin-bottom: 10px;
    --status-indicator-size: calc(5px + 1vmin);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.saveEventJsonData {
    width: 55%;
    min-height: 100vh;
    float: left;
    text-align: left;
    font-size: calc(10px + 2vmin);
    display: inline;
    border: solid;
}

.hlcView {
    display: flex;
    font-size: calc(10px + 2vmin);
    list-style-type: none;
    flex-direction: column;
    text-align: left;
    flex: 1 1 auto;
    width: 20%;
    min-height: 100vh;
    float: left;
    border: solid;
    justify-content: space-evenly;
    clear: left;
}

.hlcEntry {
    margin: 5px;
}

.hlcStatusIndicator {
    --status-indicator-size: calc(5px + 1vmin);
    margin-right: 10px;
}

.hlcViewRow {
    display: flex;
    justify-content: space-between;
    align-content: start;
}

.saveEventResultList {
    display: flex;
    font-size: calc(8px + 1vmin);
    list-style-type: none;
    flex-direction: column;
    text-align: left;
    flex: 1 1 auto;
    width: 20%;
    min-height: 100vh;
    float: left;
    border: solid;
    justify-content: start;
}

.dataView {
    clear: left;
}

.saveEventLoadingList {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 20%;
    min-height: 100vh;
    float: left;
    border: solid;
}

.loadingIcon {
    width: 20%;
}

.hlcRequestList {
    display: flex;
    font-size: calc(8px + 1vmin);
    list-style-type: none;
    flex-direction: column;
    text-align: left;
    flex: 1 1 auto;
    width: 20%;
    min-height: 100vh;
    float: left;
    border: solid;
    justify-content: start;
}

.hlcSaveEventListItem {
    margin: 5px;
    display: flex;
    justify-content: space-between;
    align-content: start;
}

.requestItemUploadedOn {
    font-size: calc(7px + .5vmin);
}

.saveEventStatus {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.queryResultListItem {
    margin: 5px;
    display: flex;
    justify-content: space-between;
    align-content: start;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  font-size: calc(5px + 1vmin);
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 5px 10px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

.hidden {
  visibility: 0;
}

.querySaveEventEntry {
   margin: 5px;
}

.clicked {
    filter: brightness(75%);
    background-color: lightgrey;
}

.requestIcon {
    height: 20px;
    width: 20px;
}
